import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCombinedConversations = (myref, query, callback) => {
    const conversations = ref([])
    const patientQuestionnaires = ref([])
    const error = ref(null)

    let conversationsRef = projectFirestore
        .collection('conversations')
        .orderBy('startedAt', 'desc')

    let questionnairesRef = projectFirestore
        .collection('patient-questionnaires')
        .orderBy('createdAt', 'desc')

    if (query) {
        conversationsRef = conversationsRef.where(...query)
        questionnairesRef = questionnairesRef.where(...query)
    }

    const unsubConversations = conversationsRef.onSnapshot(snap => {
        let results = []
        snap.docs.forEach(doc => {
            results.push({
                ...doc.data(),
                id: doc.id,
                type: 'conversation'
            })
        });
        conversations.value = results
        combineResults()
    }, err => {
        conversations.value = null
        error.value = 'could not fetch conversations data'
    })

    const unsubQuestionnaires = questionnairesRef.onSnapshot(snap => {
        let results = []
        snap.docs.forEach(doc => {
            results.push({
                ...doc.data(),
                id: doc.id,
                type: 'questionnaire'
            })
        });
        patientQuestionnaires.value = results
        combineResults()
    }, err => {
        patientQuestionnaires.value = null
        error.value = 'could not fetch questionnaires data'
    })

    const combineResults = () => {
        if (conversations.value || patientQuestionnaires.value) {
            const combined = [
                ...conversations.value || [],
                ...patientQuestionnaires.value || []
            ]

            combined.sort((a, b) => {
                return b.createdAt?.toMillis() || b.startedAt?.toMillis() -  a.createdAt?.toMillis() ||  a.startedAt?.toMillis()
            })

            myref.value = combined
            
            callback()


        }

    }

    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsubConversations()
            unsubQuestionnaires()
        });
    });

    return {
        error,
    }
}

export default getCombinedConversations