<template>
    <div class="prompter mx-5 px-3">
        <v-textarea hide-details rows="1" auto-grow @click="expandPrompt" v-model="prompt" variant="plain"
            :placeholder="placeholder" :disabled="placeholder === 'Generating...'">
            <template v-slot:append-inner>
                <v-tooltip location="top">
                    <span class="tooltip-text">Generate section with your prompt</span>
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" class="mt-auto" size="small" icon text elevation="0" @click="generate"
                            :loading="loading">
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </template>
        </v-textarea>
        <transition name="fade">
            <div v-if="showSuggestions" class="suggestions-container d-flex flex-column">
                <div class="d-flex align-center mb-4" v-if="!undoDisabled || !redoDisabled">
                    <p class="text-left font-weight-bold text-caption mb-0 mr-4" v-if="!undoDisabled || !redoDisabled">
                        Actions
                    </p>

                    <v-tooltip location="top">
                        <span class="tooltip-text">Undo</span>
                        <template v-slot:activator="{ props }">
                            <v-btn :disabled="undoDisabled" v-bind="props" size="small" variant="text" @click="undo">
                                <v-icon style="color: var(--bittersweet)">mdi-arrow-u-left-top</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                    <v-tooltip location="top">
                        <span class="tooltip-text">Redo</span>
                        <template v-slot:activator="{ props }">
                            <v-btn :disabled="redoDisabled" v-bind="props" size="small" variant="text" @click="redo">
                                <v-icon style="color: var(--bittersweet)">mdi-arrow-u-right-top</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>
                </div>
                <p class="text-left text-caption mb-0">
                    <span class="font-weight-bold">Suggestion:</span>
                    Enter a prompt to refine or expand the clinical note. For example, you can ask for more details,
                    clarify a
                    statement, transform it into a list, or make the note more concise. Your input will help generate an
                    improved version based on the existing content.
                </p>
                <!-- <v-chip class="mx-2 my-1" v-for="suggestion in suggestions" :key="suggestion"
                    @click="setPrompt(suggestion)">
                    {{ suggestion }}
                </v-chip> -->
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, watch } from 'vue';

const props = defineProps({
    loading: Boolean,
    index: Number,
    summaries: Object
});

const prompt = ref('');
const showSuggestions = ref(false);
const suggestions = ref(['Focus on this part', 'Dont do this', 'Do that', 'Random generation'])

const emit = defineEmits(['generate', 'undo', 'redo']);

const expandPrompt = () => {
    showSuggestions.value = true;
}

const setPrompt = (suggestion) => {
    prompt.value = suggestion;
}

// const generating = ref(false)
const placeholder = ref('Regenarate note section')
const generate = async () => {
    if (!prompt.value) return
    // generating.value =true
    placeholder.value = 'Generating...'

    emit('generate', prompt.value, () => {
        placeholder.value = 'Generate note section'
    });
    prompt.value = '';

}

const undo = () => {
    emit('undo')
}

const redo = () => {
    emit('redo')
}

const undoDisabled = computed(() => {
    return props?.summaries[props?.index]?.current === 0 || !props.summaries[props.index]
})

const redoDisabled = computed(() => {
    if (!props.summaries[props.index]) return true
    return props?.summaries[props?.index]?.current === props?.summaries[props?.index]?.history?.length - 1
})
</script>

<style scoped>
.prompter {
    /* border: 1px solid red; */
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 15px
}


.suggestions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
    border-top: 1px solid lightgray;
}

.v-tooltip span {
    color: white !important;
}
</style>