<template>
    <div class="d-flex sms-chat-container">
        <!-- Left sidebar with conversation list -->
        <div class="conversation-list">
            <div class="d-flex justify-space-between align-center pa-4">
                <h4>Messages</h4>
                <v-btn color="primary" @click="newSmsDialog = true" icon>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>

            <v-list v-if="!loadingConversations">
                <v-list-item v-for="conversation in conversations" :key="conversation.id"
                    :active="selectedConversation?.id === conversation.id" @click="selectConversation(conversation)"
                    class="conversation-item">
                    <v-list-item-title>
                        {{ conversation.phoneNumber }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ getLastMessage(conversation) }}
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
            <div v-else class="pa-4">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>
        </div>

        <!-- Right side chat interface -->
        <div class="chat-interface">
            <template v-if="selectedConversation">
                <div class="chat-header pa-4">
                    <h5>{{ selectedConversation.phoneNumber }}</h5>
                </div>

                <div class="message-container pa-4" ref="messageContainer">
                    <div v-for="message in selectedConversation.messages" :key="message.timestamp"
                        :class="['message', message.sender === user?.uid ? 'outgoing' : 'incoming']">
                        <div class="message-bubble">
                            {{ message.text }}
                            <div class="message-timestamp">
                                {{ formatDate(message.timestamp) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="message-input pa-4">
                    <v-form @submit.prevent="sendMessage">
                        <div class="d-flex align-center">
                            <v-textarea v-model="newMessage" rows="1" auto-grow dense hide-details
                                placeholder="Type your message..." class="mr-2"
                                @keydown.enter.prevent="sendMessage"></v-textarea>
                            <v-btn color="primary" :loading="sending" @click="sendMessage" icon>
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </template>

            <div v-else class="no-conversation-selected d-flex align-center justify-center">
                <p>Select a conversation or start a new one</p>
            </div>
        </div>

        <!-- New SMS Dialog -->
        <v-dialog v-model="newSmsDialog" max-width="500px">
            <v-card>
                <v-card-title>New Message</v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="startNewConversation">
                        <v-text-field v-model="newSms.phoneNumber" label="Phone Number" required
                            :rules="[v => !!v || 'Phone number is required']"></v-text-field>
                        <v-textarea v-model="newSms.message" label="Message" required
                            :rules="[v => !!v || 'Message is required']"></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="newSmsDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" :loading="sending" @click="startNewConversation">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Error Snackbar -->
        <v-snackbar v-model="showError" color="error" timeout="3000">
            {{ errorMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { db } from '@/firebase/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import getUser from '@/composables/getUser'
import { getUserObject } from '@/composables/getUserObject'

export default {
    name: 'SmsChat',

    setup() {
        const { user } = getUser()
        const selectedConversation = ref(null)
        const conversations = ref([])
        const newMessage = ref('')
        const sending = ref(false)
        const messageContainer = ref(null)
        const newSmsDialog = ref(false)
        const loadingConversations = ref(true)
        const showError = ref(false)
        const errorMessage = ref('')
        const userObject = ref(null)
        const newSms = ref({
            phoneNumber: '',
            message: ''
        })

        // Load conversations from Firestore
        const loadConversations = () => {

            if (!userObject.value?.clinicId) {
                console.log('No clinicId available')
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                loadingConversations.value = false
                return
            }
            console.log('Loading conversations...')
            const smsRef = collection(db, 'sms')
            const q = query(smsRef, where('clinicId', '==', userObject.value?.clinicId))
            //const smsRef = collection(db, 'sms')
            //const q = query(smsRef, where('userId', '==', user.value.uid))

            onSnapshot(q, (snapshot) => {

                conversations.value = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    messages: doc.data().messages?.sort((a, b) => a.timestamp - b.timestamp) || []
                }))
                console.log('Conversations loaded:', conversations.value)
                loadingConversations.value = false
            }, (error) => {
                console.error('Error loading conversations:', error)
                errorMessage.value = 'Error loading conversations'
                showError.value = true
                loadingConversations.value = false
            })
        }

        const selectConversation = (conversation) => {
            selectedConversation.value = conversation
            scrollToBottom()
        }

        const startNewConversation = async () => {
            if (!newSms.value.phoneNumber || !newSms.value.message) return
            if (!userObject.value?.clinicId) {
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                return
            }

            sending.value = true
            try {
                const functions = getFunctions()
                const sendTextMessage = httpsCallable(functions, 'sendSmsClient')
                await sendTextMessage({
                    to: newSms.value.phoneNumber,
                    text: newSms.value.message,
                    clinicId: userObject.value.clinicId
                })

                // Reset form and close dialog
                newSms.value = { phoneNumber: '', message: '' }
                newSmsDialog.value = false
            } catch (error) {
                console.error('Error sending message:', error)
                errorMessage.value = 'Failed to send message'
                showError.value = true
            } finally {
                sending.value = false
            }
        }

        const sendMessage = async () => {
            if (!newMessage.value.trim() || !selectedConversation.value) return
            if (!userObject.value?.clinicId) {
                errorMessage.value = 'Error: No clinic association found'
                showError.value = true
                return
            }

            sending.value = true
            try {
                const functions = getFunctions()
                const sendTextMessage = httpsCallable(functions, 'sendSmsClient')
                await sendTextMessage({
                    to: selectedConversation.value.phoneNumber,
                    text: newMessage.value,
                    clinicId: userObject.value.clinicId
                })

                newMessage.value = ''
                scrollToBottom()
            } catch (error) {
                console.error('Error sending message:', error)
                errorMessage.value = 'Failed to send message'
                showError.value = true
            } finally {
                sending.value = false
            }
        }

        const scrollToBottom = () => {
            if (messageContainer.value) {
                setTimeout(() => {
                    messageContainer.value.scrollTop = messageContainer.value.scrollHeight
                }, 100)
            }
        }

        const formatDate = (timestamp) => {
            if (!timestamp) return ''
            return new Intl.DateTimeFormat('en', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(new Date(timestamp))
        }

        const getLastMessage = (conversation) => {
            const messages = conversation.messages
            if (!messages || messages.length === 0) return 'No messages'
            return messages[messages.length - 1].text
        }

        onMounted(async () => {
            try {
                console.log('Getting user object...')
                userObject.value = await getUserObject();
                if (userObject.value?.clinicId) {
                    console.log('User object loaded:', userObject.value)
                    loadConversations()
                } else {
                    console.error('No clinic association found')
                    errorMessage.value = 'No clinic association found'
                    showError.value = true
                    loadingConversations.value = false
                }
            } catch (error) {
                console.error('Error getting user object:', error)
                errorMessage.value = 'Error loading user information'
                showError.value = true
                loadingConversations.value = false
            }
        })

        return {
            user,
            selectedConversation,
            conversations,
            newMessage,
            sending,
            messageContainer,
            newSmsDialog,
            newSms,
            loadingConversations,
            showError,
            errorMessage,
            selectConversation,
            sendMessage,
            startNewConversation,
            formatDate,
            getLastMessage
        }
    }
}
</script>

<style scoped>
.sms-chat-container {
    height: 100%;
    min-height: 600px;
}

.conversation-list {
    width: 300px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: auto;
}

.chat-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

.message-container {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 12px;
    display: flex;
}

.message.incoming {
    justify-content: flex-start;
}

.message.outgoing {
    justify-content: flex-end;
}

.message-bubble {
    max-width: 70%;
    padding: 8px 16px;
    border-radius: 16px;
    position: relative;
}

.message.incoming .message-bubble {
    background-color: white;
}

.message.outgoing .message-bubble {
    background-color: #0084ff;
    color: white;
}

.message-timestamp {
    font-size: 0.7rem;
    opacity: 0.7;
    margin-top: 4px;
}

.chat-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
}

.conversation-item {
    cursor: pointer;
}

.conversation-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
</style>