// src/utilities/templateUtils.js

/**
 * Transforms a map of template items into an ordered array of sections
 * @param {Object} itemsMap - Map where keys are section titles and values contain dlevel, style, and order
 * @returns {Array} Array of ordered section objects
 */
export const transformTemplateItems = (itemsMap) => {
    if (!itemsMap) return [];
    
    return Object.entries(itemsMap)
        .map(([sectionTitle, properties]) => ({
            sectionTitle,
            dlevel: properties.dlevel,
            style: properties.style,
            order: parseInt(properties.order, 10)
        }))
        .sort((a, b) => a.order - b.order);
};