<template>
    <v-sheet class="d-flex flex-column justify-center align-center  mt-4" color="#f9fbfd">
        <v-card class="mdhub-card elevation-0" width="100%" height="100%">
            <v-card-title class="left-align mb-5 mt-7 ml-6"> Team members</v-card-title>

            <div class="left-align mb-5 mt-7 ml-6">
                <div v-for="(clinicianEmail, index) in clinicians" :key="index" class="d-flex align-center">
                    <v-text-field type="text" :label="'Clinician ' + (index + 1)" v-model="clinicians[index]"
                        class="ml-4 mr-4 flex-grow-1" color="accent" variant="underlined" :readonly="true" />
                    <v-btn icon @click="removeClinician(index)" variant="text" hidden>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <div v-if="userStatuses[clinicianEmail]">
                        <div v-if="userStatuses[clinicianEmail].loading">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                        <div v-else-if="userStatuses[clinicianEmail].register">
                            <v-btn variant="text"
                                @click="updateUserStatus(clinicianEmail, !userStatuses[clinicianEmail].disabled)">
                                {{ userStatuses[clinicianEmail].disabled ? 'Enable' : 'Disable' }}
                            </v-btn>
                        </div>
                        <div v-else style="font-size: 0.9rem;padding: 8px">
                            Not registered
                        </div>
                    </div>
                </div>
            </div>
            <v-btn class="mb-4 mr-4" variant="text" @click="addClinicianClicked">Add Clinician</v-btn>
            <v-dialog v-model="showDialog" persistent max-width="500">
                <v-card>
                    <v-card-title class="headline mt-6 ml-4">Add Clinician Email</v-card-title>
                    <v-card-text class="ml-4 d-flex flex-wrap">
                        <v-text-field class="fixed-size" v-model="newClinicianEmail" variant="underlined"
                            :rules="emailRules" label="Email" placeholder="johndoe@gmail.com"
                            type="email"></v-text-field>
                        <div class="text-h5 d-flex align-center mr-2 ml-2">@</div>
                        <v-select class="fixed-size" v-model="selectedDomain" :items="clinic.domains"
                            variant="underlined" label="Domain"></v-select>
                    </v-card-text>
                    <v-card-actions class="mb-2">
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="addClinician" :disabled="!isEmailValid"
                            :loading="isPendingSave">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showContactSupportDialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Limit Reached</v-card-title>
                    <v-card-text>
                        Please contact us at <a href="mailto:support@mdhub.ai">support@mdhub.ai</a> to add
                        more
                        seats.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="showContactSupportDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </v-sheet>
</template>

<script>

import getUser from '@/composables/getUser'
import { getUserCollection } from '@/composables/userService';
import { ref, computed, watch } from 'vue';
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
    props: ['clinicId'],
    setup(props) {
        const { user } = getUser()
        const { error, document: clinic } = getDocument('clinics', props.clinicId)
        const { updateDocument } = useDocument('clinics', props.clinicId)
        const userObject = ref({}); // Initialize userObject as a reactive reference
        const documentHelpers = ref(null);
        const isPendingSave = ref(false)
        const clinicians = ref('')
        const showDialog = ref(false);
        const newClinicianEmail = ref('');
        const selectedDomain = ref(''); // Add this line to store the selected domain
        const showContactSupportDialog = ref(false);
        const userStatuses = ref({}); // Add this line to store user statuses

        const emailRules = [
            v => v.length > 1 || 'Email must be 2 or more characters', // New rule to check length
        ];
        const isEmailValid = computed(() => {
            return emailRules.every(rule => rule(newClinicianEmail.value) === true);
        });

        const canAddMoreClinicians = computed(() => {
            return clinicians.value.length < clinic.value.seats;
        });

        watch(clinic, (newClinic) => {
            if (newClinic.domains && newClinic.domains.length > 0) {
                selectedDomain.value = newClinic.domains[0]; // Set default domain
            }
        });

        const addClinicianClicked = () => {
            if (clinicians.value.length >= clinic.value.seats) {
                showContactSupportDialog.value = true;
            } else {
                showDialog.value = true; // This is your existing dialog for adding a clinician
            }
        };

        const addClinician = async () => {
            if (newClinicianEmail.value && selectedDomain.value) {
                const clinicianEmail = `${newClinicianEmail.value}@${selectedDomain.value}`.toLowerCase(); // Concatenate email and domain      
                console.log('LOWERCASE', clinicianEmail)
                try {
                    isPendingSave.value = true
                    const res = updateDocument({ clinicians: [...clinicians.value, clinicianEmail] });
                    clinicians.value.push(clinicianEmail);
                    selectedDomain.value = clinic.value.domains[0]; // Reset the selected domain to the first element
                    showDialog.value = false;
                    isPendingSave.value = false
                    // send email to the user 

                    await sendEmailToNewUser(clinicianEmail)
                    newClinicianEmail.value = '';
                } catch (error) {
                    isPendingSave.value = false
                }
            }
        };

        const sendEmailToNewUser = async (clinicianEmail) => {
            const emailData = {
                from: 'js@mdhub.ai',
                to: clinicianEmail,
                subject: 'Welcome to mdhub.'
            }
            let message = '';
            const functions = getFunctions();
            try {
                const checkUserStatus = httpsCallable(functions, 'checkUserStatus');
                const { data: { register, email } } = await checkUserStatus({ email: clinicianEmail });
                message = emailToUser(email, user.value.displayName);
            } catch (error) {
                console.log(`Error getting user information`, error);
            }

            emailData.message = message;
            try {
                const sendEmail = httpsCallable(functions, 'sendEmail');
                const res = await sendEmail(emailData);
                return res.data.response;
            } catch (error) {
                console.log(`Error sending email.`)
            }
        }

        const removeClinician = (index) => {
            clinicians.value.splice(index, 1);
            // Update the clinic document with the updated clinicians array
            updateDocument({ clinicians: clinicians.value });
        };



        const loadUserObject = async () => {
            userObject.value = await getUserCollection(user.value.uid);
            if (userObject.value) {
                documentHelpers.value = useDocument('user', userObject.value.id)
            }
            clinicians.value = clinic.value.clinicians
            clinicians.value.forEach(email => checkUserStatus(email)); // Check status for all clinicians
        };

        const checkUserStatus = async (email) => {

            userStatuses.value[email] = { loading: true };

            const functions = getFunctions();
            const checkUserStatus = httpsCallable(functions, 'checkUserStatus');
            try {
                const res = await checkUserStatus({ email });
                userStatuses.value[email] = { ...res.data, loading: false };
            } catch (error) {
                console.log(`Error getting user information`)
                userStatuses.value[email] = { loading: false, error: true };
            }
        };

        const updateUserStatus = async (email, disable) => {

            userStatuses.value[email] = { ...userStatuses.value[email], loading: true };

            const functions = getFunctions();
            const updateUserStatus = httpsCallable(functions, 'updateUserStatus');
            try {
                const res = await updateUserStatus({ email, disable });
                console.log(`updateUserStatus: ${JSON.stringify(res.data, null, 2)} `)
                userStatuses.value[email] = res.data;
            } catch (error) {
                console.log(`Error getting user information`)
                userStatuses.value[email] = { ...userStatuses.value[email], loading: false, error: true };
            }
        };


        loadUserObject();

        const emailToUser = (clinicianEmail, doctorName) => {
            return `
     <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 0; padding: 20px;">
                    <h2>${clinic.value.name} invited you to mdhub</h2>
        <p>Hi ${clinicianEmail},</p>
        <p>${doctorName} invited you to ${clinic.value.name} on <a href="https://www.mdhub.ai">mdhub.ai</a>
 - your new clinical AI assistant, designed to save you time and elevate patient care by automating documentation pre-, during- and post-patient visits.</p>
        <p>If you're new to mdhub, <a href="https://app.mdhub.ai/signup?fromWebsite=true">click on this link to get started</a>.  If you already have an account with this email, the account will be automatically updated.</p>
        <p>Questions? Just reply to this email.</p>
        <p>Best,<br>Jost</p>
    </div>
            `
        }

        return {
            user, userObject, isPendingSave, clinic, clinicians, showDialog, newClinicianEmail, addClinician, removeClinician,
            emailRules, isEmailValid, canAddMoreClinicians, showContactSupportDialog, addClinicianClicked, selectedDomain, checkUserStatus, userStatuses, updateUserStatus
        }
    }
}

</script>

<style scoped>
.left-align {
    text-align: left;
}

.fixed-size {
    width: 100px;
    height: 50px;
}
</style>