<template>
    <div class="container">

        <ModalOnboarding v-if="!counter && !patients || !patients.length" :isModalOpen="isModalOpen"
            :clinicId="userObject?.clinicId" @close-modal="handleModalClose" />
        <div v-if="patients">
            <PatientTable @fetchPatients="loadUserObject" :patients="patients" :gettingPatients="gettingPatients">
            </PatientTable>
        </div>
        <Feedback :userEmail="user.email" />
        <CreateSubscription :details="false"></CreateSubscription>
    </div>
</template>

<script>
import getUser from '@/composables/getUser'
import getCollectionPatient from '@/composables/getCollectionPatient'
import ListView from '@/components/ListView.vue'
import Feedback from '@/components/Feedback.vue'
import PatientTable from '@/components/PatientTable.vue'
import ModalOnboarding from '@/components/ModalOnboarding.vue';
import { ref, watch, watchEffect } from 'vue';
import CreateSubscription from '../user/CreateSubscription.vue'
import { getUserObject } from '@/composables/getUserObject'

export default {
    components: { ListView, Feedback, PatientTable, ModalOnboarding, CreateSubscription },
    methods: {
        openModal() {
            this.isModalOpen = true;
        },
        handleModalClose() {
            this.isModalOpen = false;
        },
    },
    setup() {
        const { user } = getUser()
        const isModalOpen = ref(false);
        const patients = ref([]);
        const userObject = ref({});
        const counter = ref(0);
        const gettingPatients = ref(true);

        const loadUserObject = async () => {
            const clinicId = userObject.value?.clinicId
            gettingPatients.value = true;
            const { documents, error } = await getCollectionPatient('patients', ['userId', '==', user.value.uid,
                'clinicId', '==', clinicId], user.value.uid, clinicId,)
            patients.value = documents.value;
            gettingPatients.value = false;
        };

        watchEffect(async () => {

            userObject.value = await getUserObject();

            if (userObject.value) {
                loadUserObject();
            }
        });

        watch((patients), (newPatients) => {
            isModalOpen.value = false
            if (userObject.value) {
                counter.value = userObject.value.counter
                isModalOpen.value = !counter.value && newPatients.length === 0;
            }
        });



        return { gettingPatients, counter, patients, user, isModalOpen, loadUserObject, userObject }
    }
}
</script>

<style scoped>
.feedback-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}
</style>
