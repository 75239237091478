<template>
    <v-card v-if="!!questionnaire" class="pb-4 px-4 rounded-card" elevation="0">
        <v-progress-linear v-if="savingDetails" color="var(--melon)" indeterminate
            style="width:75vw;"></v-progress-linear>
        <div class="pa-0 pt-3 text-start">
            <span class="d-flex align-center justify-space-between header-section">
                <h3>Questionnaire Details</h3>
                <v-btn class="save-all-btn" @click="saveAndGoBack" elevation="0" :loading="pendingSave"
                    color="var(--bittersweet)">
                    <v-icon color="white" class="mr-2 save-all-icon">mdi-content-save-all-outline</v-icon>
                    <span class="save-all-text" v-if="!isMobile" style="color:white; text-transform: none;">
                        Save & Go Back
                    </span>
                </v-btn>
            </span>
            <p>Basic details of your questionnaire</p>
        </div>
        <v-text-field label="Title" v-model="questionnaire.title"></v-text-field>
        <v-textarea label="Description" auto-grow v-model="questionnaire.description"></v-textarea>
        <v-checkbox dense hide-details label="Share with Community" v-model="questionnaire.shared"></v-checkbox>
        <v-checkbox v-if="userObject?.isAdmin && userObject?.clinicId" dense hide-details label="Share with Clinic"
            v-model="sharedWithClinic"></v-checkbox>
    </v-card>
</template>

<script setup>
import { defineProps, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getUserCollection } from '@/composables/userService';
import getUser from '@/composables/getUser'

const { user } = getUser();

const props = defineProps({
    questionnaire: Object,
    savingDetails: Boolean
});
const router = useRouter();
const userAgent = navigator.userAgent;
const isMobile = /Mobile|Android|iOS/i.test(userAgent);
const pendingSave = ref(false);

const saveAndGoBack = () => {
    pendingSave.value = true
    setTimeout(() => {
        pendingSave.value = false
        router.push({ name: 'Questionnaires' });
    }, 1000);
}

const sharedWithClinic = ref(false);
const userObject = ref(null);
onMounted(async () => {
    const obj = await getUserCollection(user.value.uid);
    userObject.value = obj;


    sharedWithClinic.value = !!props.questionnaire?.clinicId;
})

watch(sharedWithClinic, (newVal) => {
    if(newVal) {
        props.questionnaire.clinicId = userObject.value.clinicId;
    } else {
        props.questionnaire.clinicId = '';
    }
})
</script>

<style scoped>
.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

@media screen and (max-width: 800px) {
    .save-all-icon {
        margin-right: 0 !important;
    }

    .save-all-text {
        display: none;
    }

}

@media screen and (max-width: 600px) {
    .header-section {
        display: block !important;
    }
}
</style>