<template>
    <!-- <div v-if="fetchingInteractions" class="d-flex flex-column align-center py-8 ">
        <SavingSpinner :label="'Fetching phone calls'" style="position:relative" />
    </div> -->
    <!-- <div style="text-align:left" class="d-flex flex-column align-center py-8"
        v-if="!chatBots.length && !fetchingInteractions">No active conversations at
        the
        moment
    </div> -->
    <div class="d-flex chat-app-container">
        <div class="chat-list pr-4 rounded-border ml-4" style="width: 40%;">
            <div class="ma-4">
                <h5 class="text-h5 mb-0 text-left">AI Care Coordinator </h5>
                <p class="text-left text-body-2">Review and manage patient intake interactions handled by Sarah.</p>
            </div>
            <v-list class="chat-list-items">
                <v-list-item v-if="conversations.length > 0" v-for="(conversation) in conversations"
                    @click="selectConversation(conversation)"
                    :class="{ 'selected-chat': conversation.id === selectedConversation?.id }" class="mb-2 ">
                    <template v-slot:prepend>
                        <v-avatar :color="conversation.type === 'conversation' ? 'grey-lighten-4' : 'grey-lighten-4'"
                            size="48">
                            <v-icon icon="mdi-phone-outline" v-if="conversation.type === 'conversation'"
                                color="grey-darken-1"></v-icon>
                            <v-icon icon="mdi-file-document-outline" v-else color="grey-darken-1"></v-icon>
                        </v-avatar>
                    </template>
                    <v-list-item-title style="text-align: left;">{{ formatDate(conversation.startedAt ||
                        conversation.createdAt)
                        }}</v-list-item-title>


                    <template v-slot:append>
                        <div
                            v-if="conversation.tasks && conversation.tasks?.filter(task => task.status === 'PENDING' || task.status === 'FAILED' || task.status === 'IN_PROGRESS').length > 0">

                            <v-tooltip
                                :text="'Uncompleted tasks: ' + conversation.tasks?.filter(task => task.status === 'PENDING' || task.status === 'FAILED' || task.status === 'IN_PROGRESS')?.map(task => task.name).join(', ')">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" color="bittersweet">mdi-check-circle</v-icon>
                                </template>
                            </v-tooltip>
                        </div>
                        <div
                            v-if="conversation.tasks && conversation.tasks?.filter(task => task.status === 'COMPLETED').length === conversation?.tasks.length">
                            <v-tooltip text="All tasks have been completed">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" color="green">mdi-check-circle</v-icon>
                                </template>
                            </v-tooltip>
                        </div>

                        <v-list-item-subtitle v-if="!conversation.endedAt && conversation?.type === 'conversation'"
                            style="color:green">
                            <v-icon color="green" size="12" class="pulsing-icon">mdi-circle</v-icon>
                            LIVE
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="userObject?.isAdmin">
                            <v-btn color="grey-lighten-1" icon="mdi-delete" variant="text"
                                @click.stop="selectConversationToDelete(conversation)"></v-btn>
                        </v-list-item-subtitle>
                    </template>
                </v-list-item>
                <v-list-item>
                    <v-progress-circular indeterminate v-if="!conversations"></v-progress-circular>
                    <!-- <p v-else-if="conversations?.length === 0">Empty</p> -->
                </v-list-item>
            </v-list>
        </div>


        <div class="chat-interface pl-4">
            <v-card class="rounded-border" elevation="0" v-if="selectedConversation">
                <v-tabs v-model="tab" slider-color="var(--bittersweet)" class="patient-chart-options">
                    <v-tab value="tasks">Tasks</v-tab>
                    <v-tab value="conversation">Conversation</v-tab>
                    <v-tab value="summary">Report</v-tab>
                </v-tabs>
                <v-window class="window pa-4 mt-4" v-model="tab">
                    <v-window-item value="conversation">
                        <div id="chat" class="chat-messages chat-container">
                            <template v-for="message in interactions">
                                <div :class="['message', message.speaker === 'ai-agent' ? 'bot-message' : 'user-message']"
                                    v-if="message.text">
                                    <v-avatar size="32" class="mr-2 bordered-avatar"
                                        :class="{ 'ml-2': message.speaker == 'Patient' }">
                                        <v-icon :color="message.speaker === 'ai-agent' ? '#B0B0B0' : '#B0B0B0'"
                                            :icon="message.speaker === 'ai-agent' ? 'mdi-phone' : 'mdi-account'">
                                        </v-icon>

                                    </v-avatar>
                                    <div class="message-content">
                                        <p class="message-sender">{{ message.speaker === 'ai-agent' ? 'Sarah' :
                                            'Patient' }}</p>
                                        <p class="message-text">{{ stripTags(message.text) }}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </v-window-item>
                    <v-window-item value="summary">
                        <div class="report-container">
                            <div v-if="canRegenerate && selectedConversation.type === 'conversation'"
                                class="generate-button-container">
                                <v-btn :loading="generatingSummary" variant="tonal" color="primary"
                                    prepend-icon="mdi-refresh" class="generate-btn"
                                    @click="regenerateSummary(selectedConversation?.id)">
                                    Generate Report Again
                                </v-btn>
                            </div>
                            <div class="text-left pl-8 summary-text" v-html="selectedConversation.summary"></div>
                        </div>

                    </v-window-item>
                    <v-window-item value="tasks">
                        <div class="tasks-container">
                            <div v-for="task in displayTasks" :key="task.id" @click="selectTask(task)"
                                class="task-card">
                                <div class="task-header">
                                    <div class="task-title">
                                        <v-icon icon="mdi-checkbox-marked-circle" :color="getStatusColor(task.status)"
                                            class="mr-2" />
                                        {{ getTaskDisplayName(task.name) }}
                                    </div>
                                    <v-chip :color="getStatusColor(task.status)" variant="outlined" size="small">
                                        {{ task.status }}
                                    </v-chip>
                                </div>
                                <div class="task-description">
                                    {{ task.description }}
                                </div>
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-card>

        </div>

        <v-navigation-drawer v-model="deleteDrawer" temporary location="right" :width="800">
            <div class="d-flex flex-column align-start pa-3" v-if="conversationToDelete">
                <h4 class="text-left">Are you sure you want to delete the conversation made on {{
                    formatDate(conversationToDelete?.startedAt || conversationToDelete?.createdAt) }}?</h4>
                <div class="mt-5">
                    <v-btn elevation="0" width="auto" class="mdhub-btn" @click="deleteDrawer = false">
                        Cancel</v-btn>
                    <v-btn elevation="0" width="auto" style="text-transform: none;" class="ml-2" :loading="deleting"
                        @click="deleteConversation">Delete
                        Conversation</v-btn>
                </div>
            </div>
        </v-navigation-drawer>

        <v-dialog v-model="taskDialog" width="800" @keydown.esc="taskDialog = false">
            <v-card class="task-dialog">
                <div class="dialog-header">
                    <h3>{{ getTaskDisplayName(selectedTask?.name) }}</h3>
                    <p class="description">{{ selectedTask?.description }}</p>
                </div>

                <div class="dialog-content">
                    <div v-if="selectedTask?.metadata && selectedTask.metadata.length > 1" class="metadata-fields">
                        <div v-for="(field, index) in selectedTask.metadata.slice(1)" :key="index"
                            class="metadata-field">
                            <template v-if="parseMetadataField(field)">
                                <div class="field-label">{{ parseMetadataField(field).label }}</div>
                                <div class="field-value-container">
                                    <v-text-field :model-value="parseMetadataField(field).value" readonly
                                        density="compact" variant="outlined"
                                        :placeholder="parseMetadataField(field).value ? '' : 'Not provided'"
                                        class="field-input"></v-text-field>
                                    <v-btn v-if="parseMetadataField(field).value" icon="mdi-content-copy" size="small"
                                        variant="text" @click="copyToClipboard(parseMetadataField(field).value)"
                                        class="copy-btn"></v-btn>
                                </div>
                            </template>
                        </div>
                    </div>


                    <div class="status-section">
                        <h4 class="status-label">Update Status</h4>
                        <v-select :items="['COMPLETED', 'FAILED', 'PENDING', 'IN_PROGRESS']" v-model="updateStatus"
                            variant="outlined" density="compact"></v-select>
                    </div>
                </div>

                <v-divider></v-divider>

                <div class="dialog-actions">
                    <v-btn variant="text" @click="taskDialog = false">Cancel</v-btn>
                    <v-btn color="var(--bittersweet)" class="ml-3" @click="updateTask()" :loading="updatingTask">
                        Update
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import moment from 'moment'
import { Timestamp } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getUserObject } from '@/composables/getUserObject'
import { regenerateConversationSummaryWhitelist } from '@/composables/isWhitelistedAccount'
import getInteractions from '@/composables/getInteractions'
import getCombinedConversations from '@/composables/getCombinedConversations'

const canRegenerate = ref(false)
canRegenerate.value = regenerateConversationSummaryWhitelist()

const stripTags = (text) => {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, "") : '';
}

const formatDate = (date) => {
    return moment(new Timestamp(date.seconds, date.nanoseconds).toDate()).format('dddd, MMMM Do, YYYY HH:mm')
}

const tab = ref('conversation')
const generatingSummary = ref(false)
const functions = getFunctions();

const regenerateSummary = async (id) => {
    if (generatingSummary.value) return;
    generatingSummary.value = true;
    const generateConversationSummary = httpsCallable(functions, 'generateConversationSummary');
    await generateConversationSummary({ id })
    generatingSummary.value = false;
}

const userObject = ref(null);
const conversations = ref([])
onMounted(async () => {
    userObject.value = await getUserObject();

    const { error } = getCombinedConversations(conversations, ['clinicId', '==', userObject.value.clinicId], reselect)
})

const getTaskDisplayName = (taskName) => {
    const taskMapping = {
        createSummary: 'Create Summary',
        createPatient: 'Create Patient',
        scheduleAppointment: 'Schedule Appointment',
        addInsurance: 'Add Insurance',
        verifyInsurance: 'Verify Insurance',
        messageBillingTeam: 'Message Billing Team',
        confirmAppointment: 'Confirm Appointment',
        cancelAppointment: 'Cancel Appointment',
        scheduleNextAvailableAppointment: 'Schedule Next Available Appointment',
        medicationRefill: 'Medication Refill'
    }

    return taskMapping[taskName] || taskName // Returns the mapped name or original if not found
}

const parseMetadataField = (field) => {
    // Skip if no colon in string
    if (!field.includes(':')) return null

    const [label, ...valueParts] = field.split(':')
    let value = valueParts.join(':').trim() // Rejoin in case value contains colons

    return {
        label,
        value: value === 'undefined' ? '' : value
    }
}


const getStatusColor = (status) => {
    switch (status) {
        case 'COMPLETED':
            return 'success'
        case 'PENDING':
            return 'bittersweet'
        case 'FAILED':
            return 'error'
        case 'IN_PROGRESS':
            return 'info'
        default:
            return 'grey'
    }
}

const displayTasks = computed(() => {
    if (!selectedConversation.value) return []

    const conversationTask = {
        id: 'interaction',
        name: 'Patient Interaction',
        description: 'Review the conversation between Sarah and the patient',
        status: selectedConversation.value.endedAt || selectedConversation.value.createdAt ? 'COMPLETED' : 'IN_PROGRESS'
    }

    return [conversationTask, ...selectedConversation.value.tasks || []]
})

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text)
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}


const selectedConversation = ref(null)
const interactions = ref(null)
const selectConversation = (conversation, isInitialSelection = false) => {
    if (conversation?.type === 'conversation') {
        const { error, documents } = getInteractions(conversation.id, interactions);
    } else {
        const tempInteractions = []

        for (let question of conversation?.schema) {
            if (question.type !== 'input_title') {
                tempInteractions.push({
                    speaker: 'ai-agent',
                    text: question.title
                })

                if (question.type === 'input_multiple') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response.join(', ')
                    })
                }
                else if (question.type === 'input_list') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response?.map(res => {
                            return res?.col + ': ' + res.responses.join(', ')
                        }).join(', ')
                    })
                }
                else if (question.type === 'input_likert') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response?.map(res => {
                            return res.question + ': ' + res.response
                        }).join(', ')
                    })
                }
                else {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response
                    })
                }

            }
        }

        interactions.value = tempInteractions

    }
    selectedConversation.value = conversation
    if (isInitialSelection) {
        tab.value = 'tasks'
    }
    console.log(selectedConversation.value)

}


const conversationToDelete = ref(null)
const deleteDrawer = ref(false)
const selectConversationToDelete = (conversation) => {
    conversationToDelete.value = conversation
    deleteDrawer.value = true
}
const deleting = ref(false)
const deleteConversation = async () => {
    deleting.value = true
    const deleteConversationFunction = httpsCallable(functions, 'deleteConversation');
    try {
        await deleteConversationFunction({ id: conversationToDelete.value.id, type: conversationToDelete.value.type })
    } catch (e) {
        console.error(e)
    }
    finally {
        deleting.value = false
        deleteDrawer.value = false
    }
}

const selectedTask = ref(null)
const taskDialog = ref(false)
const updateStatus = ref('')
const selectTask = (task) => {
    if (task.name === 'Patient Interaction') {
        tab.value = 'conversation'
        return
    }
    updateStatus.value = task.status
    selectedTask.value = task
    taskDialog.value = true
}

const updatingTask = ref(false)
const updateTask = async () => {
    const updateTaskFunction = httpsCallable(functions, 'updateTask');
    updatingTask.value = true
    try {
        const type = selectedConversation.value.type === 'conversation' ? 'conversations' : 'patient-questionnaires'
        await updateTaskFunction({ entityId: selectedConversation.value.id, taskId: selectedTask.value.id, status: updateStatus.value, type })
        taskDialog.value = false
        reselect()
    } catch (err) {
        console.log(err)
    }
    finally {
        updatingTask.value = false
    }
}

const reselect = () => {
    if (!selectedConversation.value && conversations.value) {
        selectConversation(conversations.value[0], true)
        return
    }
    selectedConversation.value = conversations.value?.find(conversation => conversation.id === selectedConversation.value.id)
}
</script>

<style scoped>
.chat-app-container {
    height: 90vh;
    display: flex;
    width: 100%;
    position: fixed;
    padding-top: 14px;
    box-sizing: border-box;
    overflow: hidden;
}

.summary-text {
    line-height: 2;
}

.chat-list {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
}

.chat-interface {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 4%;
}

.chat-interface .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;
}


.chat-list-items {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 100px);
}

.window {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 100px);
}



.chat-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-y: scroll;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
}

.message {
    display: flex;
    align-items: flex-start;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
    flex-direction: row-reverse;
}

.message-content {
    background-color: #e8edf2;
    text-align: left;
    border-radius: 14px;
    padding: 8px 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.bordered-avatar {
    border: 1px solid;
    border-color: #B0B0B0;
}

.bot-message .bordered-avatar {
    border-color: #B0B0B0;
}

.user-message .message-content {
    background-color: white;
    border: 0.5px solid #B0B0B0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.message-sender {
    font-weight: bold;
    margin-bottom: 4px;
}

.message-text {
    margin: 0;
}

.selected-chat {
    background-color: #e8edf2;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.75;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0.75;
    }
}

.pulsing-icon {
    animation: pulse 1.5s infinite;
}

.patient-chart-options ::v-deep .v-tab--selected span {
    color: var(--bittersweet);
    font-size: medium;
}

.tasks-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;
}

.task-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.task-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.task-title {
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    color: #2c3e50;
}

.task-description {
    color: #666;
    font-size: 0.95em;
    line-height: 1.5;
    display: flex;
}

.report-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.generate-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
}

.generate-btn {
    font-weight: 500;
    text-transform: none;
    min-width: 180px;
}

.task-dialog {
    padding: 0;
}

.dialog-header {
    padding: 20px 24px;
}

.dialog-header h3 {
    font-size: 1.5rem;
}

.description {
    color: #666;
    font-size: 0.95rem;
    margin: 0;
}

.dialog-content {
    padding: 20px 24px;
}

.metadata-fields {
    display: grid;
}

.metadata-field {
    display: flex;
    flex-direction: column;
}

.field-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
}

.field-value-container {
    display: flex;
    align-items: center;
}

.field-input {
    flex: 1;
}

.status-section {
    margin-top: 16px;
}

.status-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 8px;
}

.dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
}
</style>