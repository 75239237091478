<template>
    <div class="d-flex flex-column">
        <v-text-field :disabled="!!item.cannotEdit" label="Title" v-model="item.title"></v-text-field>
        <v-textarea :disabled="!!item.cannotEdit" v-model="item.description" label="Description"></v-textarea>

        <p class="text-left mb-0">Type</p>
        <p class="text-left text-caption">Select the type of scale you want to use</p>
        <v-radio-group :disabled="!!item.cannotEdit" v-model="item.scale" hide-details>
            <v-radio v-for="scale in scales" :label="scale.label" :value="scale"></v-radio>
        </v-radio-group>
        <p v-if="item.scale" class="mt-1 text-left text-caption" style="color: var(--bittersweet)">{{ item.scale.items.join(', ') }}</p>

        <p class="mt-3 mr-auto">Questions</p>
        <span v-for="(_, i) in item.questions">
            <v-text-field :disabled="!!item.cannotEdit" v-model="item.questions[i]" :key="i"
                :label="`Question ${i + 1}`">
                <template v-slot:append-inner v-if="i > 0">
                    <v-icon @click="deleteQuestion(i)">mdi-delete</v-icon>
                </template>
            </v-text-field>
        </span>
        <v-btn v-if="!item.cannotEdit" width="200" class="mt-3" @click="item.questions.push('')">Add item</v-btn>
        <v-checkbox :disabled="!!item.cannotEdit" class="mt-2" v-model="item.required" label="Required"></v-checkbox>
        <CardActions :item="item" @save="save" @remove="remove" @moveUp="moveUp" @moveDown="moveDown"
            @duplicate="duplicate" />
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import CardActions from './CardActions.vue';

const props = defineProps({
    item: Object,
})

onMounted(() => {
    if (!props.item.questions) {
        props.item.questions = ['']
    }
    if (!props.item.scale){
        props.item.scale = scales[0]
    }
})

const emit = defineEmits(['save', 'remove', 'moveUp', 'moveDown', 'duplicate']);


const deleteQuestion = (index) => {
    props.item.questions.splice(index, 1)
}

const save = () => {
    emit('save')
}

const remove = () => {
    emit('remove', {
        id: props.item.id
    })
}

const moveUp = () => {
    emit('moveUp')
}

const moveDown = () => {
    emit('moveDown')
}

const duplicate = () => {
    emit('duplicate')
}

const scales = [
    {
        label: '4-point scale',
        value: '4_point_scale',
        items: [
            'Very dissatisfied',
            'Dissatisfied',
            'Satisfied',
            'Very satisfied'
        ]
    },
    {
        label: '5-point scale',
        value: '5_point_scale',
        items: [
            'Very difficult',
            'Somewhat difficult',
            'Neither difficult nor easy',
            'Somewhat easy',
            'Very easy'
        ]
    },
    {
        label: '6-point scale',
        value: '6_point_scale',
        items: [
            'Very unimportant',
            'Unimportant',
            'Somewhat unimportant',
            'Somewhat important',
            'Important',
            'Very important'
        ]
    },
    {
        label: '7-point scale',
        value: '7_point_scale',
        items: [
            'Strongly disagree',
            'Disagree',
            'Somewhat disagree',
            'Neither agree nor disagree',
            'Somewhat agree',
            'Agree',
            'Strongly agree'
        ]
    },
    {
        label: '10-point scale',
        value: '10_point_scale',
        items: [
            '1 = Very unimportant',
            '2',
            '3',
            '4',
            '5 = Neither important nor unimportant',
            '6',
            '7',
            '8',
            '9',
            '10 = Extremely important'
        ]
    },
    {
        label: 'Frequency scale',
        value: 'frequency_scale',
        items: [
            'Never',
            'Rarely',
            'Sometimes',
            'Often',
            'Always'
        ]
    },
    {
        label: 'Frequency scale with N/A',
        value: 'frequency_scale_with_na',
        items: [
            'Never',
            'Rarely',
            'Occasionally',
            'Frequently',
            'Very Frequently',
            'Non Applicable'
        ]
    }
]
</script>