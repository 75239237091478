
import getUser from "./getUser";
import { getUserObject } from '@/composables/getUserObject'
import { getFunctions, httpsCallable } from "firebase/functions";

const { user } = getUser()
const isWhiteListedAccount = () => {

  const whiteListEmails = ['js@mdhub.ai', 'vk@mdhub.ai', 'efrenalla@gmail.com', 'eal@mdhub.ai', 'dm@mdhub.ai', 'gk@mdhub.ai', 'by@mdhub.ai', 'kasi@mdhub.ai']; //mdhub accounts 
  const whiteListIds = ['lkNt1GHrTOSXNCiJCbkizfK6xgk2', 'JdBtMXU1VtPkgR9A4YOUiIhn20v2', 'Khu0Nf1bfdaTyOQMWylVOIYYFC13', 'dez9j6NgLFcxMwnLd3TvvaGF9xT2', '9nazQRT0ONMzOYv4aywDA1KqI7L2', 'pyc2kk9ThZYXOQb9OFUssPccxgG3', 'dIoCv4AVQWaGR0SgHl9nz4h2H8v2', 'stSMUKQVhFWdxLsZ6cmjPBbLySa2', 'CzKFHx7eJNUySJyWNEk6eizTRJE3', 'G3FaDHtn7WhaTSPcVmsCTA4wpjr2', 'cboX1cpFYwPQRNIKJerPqAouSag2', 'LtQKJ18fBYSLex5srYOChhohdt63', 'MJk5gKQVEyWmPBzZxXzADqbP4Uf1', 'pml7OHfIA4TGQkdbzRgBOSdbWbw2', 'kogomJi606d7nUbxcynjSbJM6s83']; // non mdhub accounts

  if (!user.value) return false
  return whiteListIds.includes(user.value.uid) || whiteListEmails.includes(user.value.email);
}

const newClinicalNoteWhiteListedAccounts = () => {
  return !!user.value;
}

async function getWhitelistedFeaturesForUser() {
  const userId = user.value.uid;
  //console.log('Fetching whiteisted features for the user ID', userId);

  const functions = getFunctions();
  const getWhitelistedFeaturesForUser = httpsCallable(functions, 'getWhitelistedFeatures');

  try {
    const whitelistedFeatures = (await getWhitelistedFeaturesForUser({ userId }))?.data;
    //console.log('Received whitelisted features for the user ID', { userId, whitelistedFeatures });
    localStorage.setItem('whitelistedFeatures', JSON.stringify(whitelistedFeatures));
    return whitelistedFeatures;
  } catch (error) {
    console.error('Failed ot fetch whitelisted features for the user ID', error);
  }
}

function resetWhitelistedFeatures() {
  localStorage.removeItem('whitelistedFeatures');
}

const previousNoteWhitelist = () => {
  const whiteListEmails = ['js@mdhub.ai', 'vk@mdhub.ai', 'efrenalla@gmail.com', 'eal@mdhub.ai', 'dm@mdhub.ai', 'gk@mdhub.ai', 'by@mdhub.ai', 'kasi@mdhub.ai']; //mdhub accounts 

  if (!user.value) return false
  return whiteListEmails.includes(user.value.email);
}

const newRecorderWhitelist = () => {
  const whiteListedFeatures = JSON.parse(localStorage.getItem('whitelistedFeatures'));
  return whiteListedFeatures?.includes('new-recorder')
}

const ehrExtensionWhitelist = () => {
  const whiteListedFeatures = JSON.parse(localStorage.getItem('whitelistedFeatures'));
  return whiteListedFeatures?.includes('ehr-extension')
}

const ehrExtensionWhitelistRula = async () => {
  const rulaPsych = 'oo6ixRtqatMyHFv2dMl4'
  const rula = 'ufRg8WxLVolWVUQR1Iq9'

  const userObject = await getUserObject();

  if (!user.value) return false
  if (!userObject) return false
  if (userObject.clinicId === rula || userObject.clinicId === rulaPsych) {
    console.log('Rula clinic with clinic ID', userObject.clinicId)
    return true
  } else {
    return false
  }
}

const treatmentPlanWhitelist = () => {
  const whiteListedFeatures = JSON.parse(localStorage.getItem('whitelistedFeatures'));
  return whiteListedFeatures?.includes('treatment-plan')
}

const regenerateConversationSummaryWhitelist = () => {
  const whiteListEmails = ['js@mdhub.ai', 'vk@mdhub.ai', 'efrenalla@gmail.com', 'eal@mdhub.ai', 'dm@mdhub.ai', 'gk@mdhub.ai', 'by@mdhub.ai', 'kasi@mdhub.ai']; //mdhub accounts 

  if (!user.value) return false
  return whiteListEmails.includes(user.value.email);
}

const editViewNoteWhitelist = () => {
  const whiteListedFeatures = JSON.parse(localStorage.getItem('whitelistedFeatures'));
  return whiteListedFeatures?.includes('edit-view-note')
}

const sendSmsWhitelist = () => {
  const whiteListedFeatures = JSON.parse(localStorage.getItem('whitelistedFeatures'));
  return whiteListedFeatures?.includes('send-sms')
}

export { sendSmsWhitelist, editViewNoteWhitelist, ehrExtensionWhitelist, ehrExtensionWhitelistRula, treatmentPlanWhitelist, regenerateConversationSummaryWhitelist, newRecorderWhitelist, previousNoteWhitelist, isWhiteListedAccount, newClinicalNoteWhiteListedAccounts, getWhitelistedFeaturesForUser, resetWhitelistedFeatures }