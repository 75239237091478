<template>
    <div class="overlay-container">
        <div class="scrollable-content">
            <div class="d-flex flex-column align-center scrollable">
                <div v-if="fetchingQuestionnaire">
                    <v-progress-circular class="mt-12" color="primary" indeterminate></v-progress-circular>
                </div>
                <div v-else-if="!!errorMessage">
                    {{ errorMessage }}
                </div>

                <div class="staytop" v-if="displayedItems?.length > 0">
                    <h6 class="text-left">Questionnaire Sections</h6>
                    <div>
                        <ol class="list-unstyled">
                            <li @click="scrollToItem(item.id)" class="mb-0 text-left sectionHeader"
                                v-for="(item, index) in displayedItems" :key="item.id">
                                <span class="text-caption">{{ `${index + 1}. ${item.title}` }}</span>
                            </li>
                        </ol>
                    </div>
                </div>
                <div v-if="!shouldEditQuestionnaire" class="text-center my-4 pa-4">
                    <v-alert color="info" variant="tonal" class="viewWidth">
                        This questionnaire can only be edited by an administrator
                    </v-alert>
                </div>
                <Details :savingDetails="savingDetails" class="viewWidth mb-10" :questionnaire="questionnaire" />

                <div ref="el">
                    <div v-for="(item, i) in questionnaire?.schema" :key="item.id" class="item-container">
                        <v-divider v-if="item.type === 'input_title'" :thickness="2"
                            class="border-opacity-100 viewWidth"></v-divider>
                        <div class="card-wrapper">
                            <v-icon v-if="!editingItemId" color="var(--melon)" class="drag"
                                style="cursor: move;">mdi-dots-grid</v-icon>
                            <v-card :id="item.id" class="viewWidth rounded-card v-card"
                                :class="{ 'mt-8': item.type === 'input_title' }" elevation="0" :link="false">
                                <v-card-text v-if="editingItemId === item.id" id="selectedCard" class="selectedCard">
                                    <OnClickOutside @trigger="clickOutside(item)">
                                        <v-select label="Type" :items="selectItems" item-title="text" item-value="type"
                                            :disabled="!!item.cannotEdit" @update:menu="updateMenuState"
                                            v-model="item.type">
                                            <template v-slot:prepend-inner>
                                                <v-tooltip location="top">
                                                    <template v-slot:activator="{ props }">
                                                        <v-icon v-bind="props" icon="mdi-help-circle-outline"></v-icon>
                                                    </template>
                                                    I'm a tooltip
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <component :is="renderComponent(item.type)" :item="item" :key="i"
                                            @remove="remove" @moveUp="moveUp(item.id)" @moveDown="moveDown(item.id)"
                                            @save="save(item)" @duplicate="duplicate(item)">
                                        </component>
                                    </OnClickOutside>
                                </v-card-text>
                                <v-card-text style="z-index:500"
                                    :class="{ 'required-field-border': item.required, 'title-section': item.type === 'input_title' }"
                                    v-else>
                                    <ItemPreview @select="selectItem(item.id)" :item="item" />
                                </v-card-text>
                            </v-card>
                        </div>

                        <v-tooltip text="Add item here">
                            <template v-slot:activator="{ props }">
                                <div class="hover-container d-flex justify-center">

                                    <div class="addHereDivider" color="var(--bittersweet)"></div>

                                    <v-btn @click="addItemToQuestionnaireByIndex(i)" class="hover-button"
                                        icon="mdi-plus" v-bind="props" size="x-small"
                                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></v-btn>
                                </div>
                            </template>
                        </v-tooltip>
                    </div>
                </div>


                <div class="d-flex flex-column sticky-buttons" v-if="!fetchingQuestionnaire && !errorMessage">
                    <!-- <v-tooltip text="Add item to bottom of the questionnaire">
                <template v-slot:activator="{ props }">
                    
                    v-bind="props"
                    -->
                    <!-- <v-btn @click="helpDrawer = true" rounded elevation="0" color="var(--melon)" :disabled="editingItemId">
                <span style="color:white; text-transform: none;">
                    I need help
                </span>
            </v-btn> -->
                    <!-- </template>
            </v-tooltip> -->

                    <v-tooltip text="Add item to bottom of the questionnaire">
                        <template v-slot:activator="{ props }">
                            <v-btn class="add-item-btn" @click="addItemToQuestionnaire" rounded v-bind="props"
                                color="var(--bittersweet)" :disabled="!!editingItemId">
                                <v-icon color="white">mdi-plus</v-icon>
                                <span class="add-item-text" v-if="!isMobile" style="color:white; text-transform: none;">
                                    Add item
                                </span>

                            </v-btn>
                        </template>
                    </v-tooltip>
                </div>
                <div v-if="!shouldEditQuestionnaire" class="disable-edit-overlay"></div>
                <v-overlay :model-value="loading" :persistent="true"
                    class="align-center justify-center spinner-overlay">
                    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
                </v-overlay>

                <v-snackbar v-model="snackbar" :timeout="2000" color="gray">
                    <span> Questionnaire section saved successfully
                    </span>
                </v-snackbar>
                <v-snackbar v-model="supportSnackbar" :timeout="2000" color="gray">
                    We have received your request and will get back to you as soon as possible.
                </v-snackbar>


                <v-navigation-drawer v-model="helpDrawer" temporary location="right" :width="800">
                    <div class="pa-5 ">
                        <h5 class="text-left">Need Help Creating a Questionnaire?</h5>
                        <p class="text-left">Let our team of clinical and AI experts create your questionnaire!
                            <br>Request a quote and let us do the work for you.
                        </p>
                        <v-textarea v-model="text" variant="outlined" label="Describe your problem"></v-textarea>
                        <div class="d-flex justify-end">
                            <v-btn elevation="0" text @click="helpDrawer = false">close</v-btn>
                            <v-btn class="ml-2" elevation="0" color="var(--bittersweet)" @click="sendSupport">
                                <span style="color:white">SEND</span>
                            </v-btn>

                        </div>
                    </div>
                </v-navigation-drawer>
                <Feedback :showOnLeft="true" />
            </div>
        </div>
    </div>



</template>

<script setup>
import { ref, onMounted, nextTick, computed, watch } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import Details from '@/components/questionnaire-builder/Details.vue';
import Title from '@/components/questionnaire-builder/Title.vue';
import FreeText from '@/components/questionnaire-builder/FreeText.vue';
import List from '@/components/questionnaire-builder/List.vue';
import SingleSelection from '@/components/questionnaire-builder/SingleSelection.vue';
import MultipleSelection from '@/components/questionnaire-builder/MultipleSelection.vue';
import Number from '@/components/questionnaire-builder/Number.vue';
import Likert from '@/components/questionnaire-builder/Likert.vue';
import ItemPreview from '@/components/questionnaire-builder/ItemPreview.vue';
import QuestionnaireEmail from '@/components/questionnaires/QuestionnaireEmail.vue';
import { OnClickOutside } from '@vueuse/components'
import { useRoute } from "vue-router";
import { watchDebounced } from '@vueuse/core'
import { trackEvent, QUESTIONNAIRE_SUPPORT, QUESTIONNAIRE_SAVED, QUESTIONNAIRE_DETAILS_SAVED, QUESTIONNAIRE_MOVE_ITEM_UP, QUESTIONNAIRE_MOVE_ITEM_DOWN, QUESTIONNAIRE_ITEM_DUPLICATED } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser';
import Feedback from '@/components/Feedback.vue';
import { useSortable } from '@vueuse/integrations/useSortable'
import { getUserObject } from '@/composables/getUserObject'

const el = ref(null)

const { user } = getUser();
const userObject = ref(null);
const shouldEditQuestionnaire = ref(true);

const functions = getFunctions();
const questionnaire = ref(null);
const snackbar = ref(false);
const loading = ref(false);
const editingItemId = ref(null);

const isMenuOpen = ref(false);
const userAgent = navigator.userAgent;
const isMobile = /Mobile|Android|iOS/i.test(userAgent);
const updateMenuState = (state) => {
    isMenuOpen.value = state;
};

const selectItems = [
    { type: 'input_free_text', text: 'Free Text' },
    { type: 'input_number', text: 'Number' },
    { type: 'input_title', text: 'Title' },
    { type: 'input_list', text: 'Table' },
    { type: 'input_single', text: 'Single Selection' },
    { type: 'input_multiple', text: 'Multiple Selection' },
    { type: 'input_likert', text: 'Likert Scale' }
]

let questionnaireId;

const fetchingQuestionnaire = ref(false)
const errorMessage = ref('')

onMounted(async () => {
    const route = useRoute();
    questionnaireId = route.params.id;
    userObject.value = await getUserObject();
    console.log("USER OBJECT", userObject.value)

    fetchingQuestionnaire.value = true
    const getQuestionnaire = httpsCallable(functions, 'getQuestionnaire');
    getQuestionnaire({ questionnaireId })
        .then((result) => {
            questionnaire.value = result.data.questionnaire
            shouldEditQuestionnaire.value = (userObject.value.isAdmin &&
                userObject.value.clinicId === questionnaire.value.clinicId) ||
                user.value.uid === questionnaire.value.userId && !questionnaire.value.clinicId
            useSortable(el, questionnaire.value.schema, {
                animation: 150,
                handle: '.drag',
                onMove: (e) => {
                    const draggedTop = e.draggedRect.top; // Get the top position of the dragged item

                    // Loop through the items to find out which item is being hovered over
                    let newIndex = -1;
                    const items = el.value.children; // Get the children of the sortable container

                    for (let i = 0; i < items.length; i++) {
                        const itemRect = items[i].getBoundingClientRect(); // Get the rect of the current item

                        // Check if the dragged item is hovering over this item
                        if (draggedTop >= itemRect.top && draggedTop <= itemRect.bottom) {
                            newIndex = i; // Set the new index if hovering over this item
                            break;
                        }
                    }

                    if (newIndex !== -1) {
                        // console.log("Moving item to index:", newIndex);
                        const item = questionnaire.value.schema[newIndex];
                        nextTick(() => {
                            scrollToItem(item.id); // Scroll to the hovered item
                        });
                    }
                },
                onUpdate: (e) => {
                    const oldIndex = e.oldIndex;
                    const newIndex = e.newIndex;

                    if (oldIndex !== newIndex) {
                        const movedItem = questionnaire.value.schema.splice(oldIndex, 1)[0];
                        questionnaire.value.schema.splice(newIndex, 0, movedItem);
                    }

                    const newItem = questionnaire.value.schema[newIndex]

                    saveWholeQuestionnaireSchema(newItem.id)
                }
            })

        })
        .catch((error) => {
            errorMessage.value = error.message
            console.error(error);
        })
        .finally(() => {
            fetchingQuestionnaire.value = false
        })
})

const renderComponent = (type) => {
    switch (type) {
        case 'input_title':
            return Title;
        case 'input_free_text':
            return FreeText;
        case 'input_list':
            return List;
        case 'input_single':
            return SingleSelection;
        case 'input_multiple':
            return MultipleSelection;
        case 'input_number':
            return Number;
        case 'input_likert':
            return Likert;
        default:
            return null;
    }
}

const remove = (item) => {
    loading.value = true

    const deleteSchemaItem = httpsCallable(functions, 'deleteSchemaItem');
    deleteSchemaItem({ questionnaireId, itemId: item.id })
        .then(result => {
            questionnaire.value = result.data.questionnaire
            editingItemId.value = null

        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
            loading.value = false
            snackbar.value = true
        })
}

const moveUp = async (id) => {
    const index = questionnaire.value.schema.findIndex((item) => item.id === id)
    if (index > 0) {
        loading.value = true
        const moveSchemaItem = httpsCallable(functions, 'moveSchemaItem');
        moveSchemaItem({ questionnaireId, itemId: id, direction: 'up' }).then((result) => {
            questionnaire.value = result.data.questionnaire
            editingItemId.value = null
            trackEvent(QUESTIONNAIRE_MOVE_ITEM_UP, {
                userId: questionnaire.value.userId,
                userEmail: user.value.email,
                questionnaireId,
                itemId: id
            })
        })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                loading.value = false
                snackbar.value = true

                scrollToItem(id)
            })
    }
}

const moveDown = (id) => {
    const index = questionnaire.value.schema.findIndex((item) => item.id === id)
    if (index < questionnaire.value.schema.length - 1) {
        loading.value = true
        const moveSchemaItem = httpsCallable(functions, 'moveSchemaItem');
        moveSchemaItem({ questionnaireId, itemId: id, direction: 'down' }).then((result) => {
            questionnaire.value = result.data.questionnaire
            editingItemId.value = null
            trackEvent(QUESTIONNAIRE_MOVE_ITEM_DOWN, {
                userId: questionnaire.value.userId,
                userEmail: user.value.email,
                questionnaireId,
                itemId: id
            })
        })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                loading.value = false
                snackbar.value = true

                scrollToItem(id)
            })
    }

}
const clickOutside = async (item) => {
    if (!isMenuOpen.value) {
        await save(item)
    }
}

const savingDetails = ref(false)
watchDebounced(() => ({
    title: questionnaire.value?.title,
    description: questionnaire.value?.description,
    shared: questionnaire.value?.shared,
    clinicId: questionnaire.value?.clinicId
}), async (newVal) => {
    if (!shouldEditQuestionnaire.value) return
    savingDetails.value = true
    const updateDetails = httpsCallable(functions, 'updateDetails');
    await updateDetails({
        questionnaireId,
        title: newVal.title,
        description: newVal.description,
        shared: newVal.shared,
        clinicId: newVal.clinicId
    })
    savingDetails.value = false
    trackEvent(QUESTIONNAIRE_DETAILS_SAVED, { userId: questionnaire.value.userId, userEmail: user.value.email, questionnaireId, title: newVal.title, description: newVal.description, shared: newVal.shared })


    questionnaire.value.id = questionnaireId
}, { debounce: 1000, maxWait: 5000 })


const save = async (item) => {
    loading.value = true
    item = filterItemForSave(item)
    const updateSchemaItem = httpsCallable(functions, 'updateSchemaItem');

    const result = await updateSchemaItem({ questionnaireId, item, index: byIndex.value ?? null })
    byIndex.value = null
    questionnaire.value = result.data.questionnaire
    loading.value = false
    snackbar.value = true

    trackEvent(QUESTIONNAIRE_SAVED, { userId: questionnaire.value.userId, userEmail: user.value.email, questionnaireId, title: questionnaire.value.title })
    if (item.id === editingItemId.value) {
        editingItemId.value = null
    }
}

const duplicate = async (item) => {
    loading.value = true
    const duplicateItem = httpsCallable(functions, 'duplicateItem');

    const result = await duplicateItem({ questionnaireId, item: item })
    questionnaire.value = result.data.questionnaire
    loading.value = false
    snackbar.value = true
    trackEvent(QUESTIONNAIRE_ITEM_DUPLICATED, { userId: questionnaire.value.userId, userEmail: user.value.email, questionnaireId, item })
    selectItem(result.data.newId)
}

const saveWholeQuestionnaireSchema = async (id) => {
    loading.value = true
    const saveWholeSchema = httpsCallable(functions, 'saveWholeSchema');
    const result = await saveWholeSchema({ questionnaireId, schema: questionnaire.value.schema })
    questionnaire.value = result.data.questionnaire
    loading.value = false
    snackbar.value = true
    scrollToItem(id)
}

const selectItem = (id) => {
    editingItemId.value = id
    nextTick(() => {
        scrollToItem(id)
    })
}

// save as ref to remove it from the list if user cancels
const addingItemId = ref(null)

const addItemToQuestionnaire = async () => {
    loading.value = true;
    addingItemId.value = crypto.randomUUID();

    const item = {
        id: addingItemId.value,
        type: 'input_free_text',
        title: 'Default title',
        description: 'Default description',
        required: true,
    }

    questionnaire.value.schema.push(item)
    loading.value = false
    scrollToItem(item.id)
    editingItemId.value = addingItemId.value
}

const byIndex = ref(null)
const addItemToQuestionnaireByIndex = async (index) => {
    loading.value = true;
    addingItemId.value = crypto.randomUUID();

    const item = {
        id: addingItemId.value,
        type: 'input_free_text',
        title: 'Default title',
        description: 'Default description',
        required: true,
    }
    questionnaire.value.schema.splice(index + 1, 0, item)
    loading.value = false
    scrollToItem(item.id)
    byIndex.value = index + 1
    editingItemId.value = addingItemId.value

}




const scrollToItem = (id) => {
    nextTick(() => {
        const element = document.getElementById(id)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    })

}
const filterItemForSave = (item) => {
    switch (item.type) {
        case 'input_title':
            delete item.required
            delete item.options
            delete item.columns
            delete item.prefix
            delete item.requireComment
            delete item.questions
            break;
        case 'input_free_text':
            delete item.options
            delete item.columns
            delete item.prefix
            delete item.requireComment
            delete item.questions
            break;
        case 'input_list':
            delete item.options
            delete item.prefix
            delete item.requireComment
            delete item.questions
            break;
        case 'input_single':
            delete item.columns
            delete item.prefix
            delete item.questions
            break;
        case 'input_multiple':
            delete item.columns
            delete item.prefix
            delete item.requireComment
            delete item.questions
            break;
        case 'input_number':
            delete item.options
            delete item.columns
            delete item.requireComment
            delete item.questions
            break;
        case 'input_likert':
            delete item.options
            delete item.columns
            delete item.prefix
            delete item.requireComment
            break;
    }

    if (!item.title) item.title = 'Default title'

    item.options?.forEach((option, i) => {
        if (!option) {
            item.options[i] = `Option ${i + 1}`
        }
    })

    item.columns?.forEach((option, i) => {
        if (!option) {
            item.columns[i] = `Column ${i + 1}`
        }
    })

    item.questions?.forEach((question, i) => {
        if (!question) {
            item.questions[i] = `Question ${i + 1}`
        }
    })

    return item
}

const helpDrawer = ref(false)
const text = ref('')
const supportLoading = ref(false)
const supportSnackbar = ref(false)

const sendSupport = () => {
    if (!text.value) return;
    supportLoading.value = true;
    trackEvent(QUESTIONNAIRE_SUPPORT, { userId: user.value.uid, userEmail: user.value.email, text: text.value })
        .then(() => {
            supportSnackbar.value = true;
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            supportLoading.value = false;
            helpDrawer.value = false;
            text.value = ''
        })
}

const displayedItems = computed(() => {
    if (!questionnaire.value?.schema) return []
    return questionnaire.value?.schema?.filter(item => item.type === 'input_title')
})


</script>


<style scoped>
.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.selectedCard {
    border-left: 5px solid var(--bittersweet);
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.btnText {
    text-decoration: none;
    text-transform: none;
    color: white;
}

.form-width {
    max-width: 100% !important;
}

.send-btn {
    align-self: end;
}

.viewWidth {
    width: 700px;
}

.sticky-buttons {
    position: sticky;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: flex-end;
    margin-right: 40px;
}

.sticky-buttons-left {
    position: sticky;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: flex-start;
    margin-left: 40px;
}

::v-deep .v-timeline-item__body {
    width: 100% !important;
    padding: 0 0 0 15px !important;
}

.required-field-border {
    border-radius: 10px;
    border: 1px solid var(--bittersweet);
}

.hover-container {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px
}

.hover-button {
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease;
}

.addHereDivider {
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease;
    height: 1px;
    width: 100%;
    background-color: var(--bittersweet);
}

.hover-container:hover {
    width: 700px;
    padding-top: 20px;
    padding-bottom: 20px
}

.hover-container:hover .hover-button {
    display: block;
    opacity: 1;
}

.hover-container:hover .addHereDivider {
    display: block;
    opacity: 1;
}

.staytop {
    position: fixed;
    top: 100px;
    left: 70px;
    align-self: flex-start;
    padding: 10px;
    z-index: 1;
    max-width: 20%;
}

.sectionHeader {
    cursor: pointer;

}

.sectionHeader:hover {
    color: var(--bittersweet);
    text-decoration: underline;
}

.add-item-btn {

    border-radius: 30px !important;
    color: var(--rich-black) !important;
    flex-grow: 0;
    height: 50px !important;
    width: 200px;


    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    margin-right: 20px;

}

@media(width<=1300px) {
    .staytop {
        display: none;
    }

}

@media (width <=1100px) {
    .staytop {
        display: none
    }

    .viewWidth {
        width: 750px;
    }

    .hover-container:hover {
        width: 750px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media (width <=910px) {
    .viewWidth {
        width: 600px;
    }

    .hover-container:hover {
        width: 600px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media (width <=750px) {
    .viewWidth {
        width: 500px;
    }

    .hover-container:hover {
        width: 500px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media (width <=650px) {
    .viewWidth {
        width: 450px;
    }

    .hover-container:hover {
        width: 450px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media (width <=600px) {
    .add-item-btn {
        height: 50px !important;
        width: 10px;
        border-radius: 50px !important;

        position: fixed;
        right: 20px;
        bottom: 20px;
        display: flex;
        margin-right: 20px;

    }

    .add-item-text {
        display: none;
    }

    .viewWidth {
        width: 400px;
    }

    .hover-container:hover {
        width: 400px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media (width <=440px) {
    .add-item-btn {

        right: 10px;
        bottom: 20px;
        display: flex;
        margin-right: 20px;

    }

    .add-item-text {
        display: none;
    }

    ::v-deep .v-timeline-item__body {
        width: 100% !important;
        padding-left: 0 !important;
    }

    .viewWidth {
        width: 350px
    }

    .hover-container:hover {
        width: 350px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

.title-section {
    border-right: 20px solid var(--bittersweet);
}

.item-container {
    position: relative;
}

.card-wrapper {
    display: flex;
    align-items: center;
}

.drag {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    /* z-index: 2; */
}

.hover-container {
    position: relative;
}

.addHereDivider {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.hover-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Adjust media queries as needed */
@media (width <=600px) {
    .drag {
        left: -20px;
        /* Adjust for smaller screens */
    }
}

.sortable-container {
    max-height: 400px;
    overflow-y: auto;
}

.overlay-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.disable-edit-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);

    pointer-events: all;
    cursor: not-allowed;
    margin-bottom: -48px;
}

.scrollable-content {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>